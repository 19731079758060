<!--
 * @Author: your name
 * @Date: 2020-09-18 11:46:48
 * @LastEditTime: 2020-10-11 17:19:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\leadingCockpit\components\accountDataDrill.vue
-->
<template>
    <div class="accountDataDrill">
        <h4>
            <p>{{ title }}</p>
            <div class="light-beam"></div>
        </h4>
        <div class="wrapper">
            <!-- 表格 -->
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th class="tit" v-for="(item, index) in names" :key="`name_${index}`">
                            {{ item }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="tit">
                            累计应收
                        </td>
                        <td
                            class="num"
                            v-for="(item, index) in receivable"
                            :key="`receivable_${index}`"
                        >
                            {{ item }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tit">
                            累计实收
                        </td>
                        <td
                            class="num"
                            v-for="(item, index) in receipt"
                            :key="`receipt${index}`"
                        >
                            {{ item }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tit">
                            应收账款余额
                        </td>
                        <td
                            class="num"
                            v-for="(item, index) in receivable_balance"
                            :key="`receivable_balance${index}`"
                        >
                            {{ item }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tit">
                            货款回收率
                        </td>
                        <td
                            class="num"
                            v-for="(item, index) in rate"
                            :key="`rate${index}`"
                        >
                            {{ item }}%
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- 图表 -->
            <div class="chart" id="chart"></div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            names: [],
            // 实收
            receipt: [],
            // 应收账款余额
            receivable_balance: [],
            // 应收
            receivable: [],
            // 货款回收率
            rate: [],
            // 源数据
            sourceData: [],
            pian_q_id: '',
            title: '',
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.title = this.extr.title;
        this.pian_q_id = this.extr.pian_q_id;
        this.handleBalanceamount();
    },
    methods: {
        async handleBalanceamount() {
            const res = await this.getBalanceamount();
            if (res) {
                this.sourceData = res;
                res.map(item => {
                    this.names.push(item.name);
                    this.receipt.push(item.receipt);
                    this.receivable_balance.push(item.receivable_balance);
                    this.receivable.push(item.receivable);
                    if (item.rate) {
                        this.rate.push(item.rate.substr(0, item.rate.length - 1));
                    } else {
                        this.rate.push(0);
                    }
                });
                const data = {
                    xAxis: { data: this.names },
                    receipt: this.receipt,
                    receivable_balance: this.receivable_balance,
                    receivable: this.receivable,
                    rate: this.rate,
                };
                this.lineColumn('chart', data);
            }
        },
        accountDrill(name) {
            this.$parent.hide();
            let pian_q_id = '';
            const arr = this.sourceData.filter(item => item.name === name);
            if (arr.length > 0) {
                pian_q_id = arr[0].code;
            }
            this.$toast({
                title: false,
                type: 'eject',
                width: '12rem',
                t_url: 'leadingCockpit/components/accountsReceivableDrill/areaDrill',
                viewPosition: 'view',
                extr: {
                    title: name,
                    pian_q_id: pian_q_id,
                },
            });
        },
        toEnter(name) {
            this.$parent.hide();
            let qi_y_id = '';
            const arr = this.sourceData.filter(item => item.name === name);
            if (arr.length > 0) {
                qi_y_id = arr[0].code;
            }
            this.$toast({
                title: false,
                type: 'eject',
                width: '12rem',
                t_url: 'leadingCockpit/components/accountsReceivableDrill/enterDrill',
                viewPosition: 'view',
                extr: {
                    title: name,
                    qi_y_id: qi_y_id,
                },
            });
        },
        // 钻取应收账款余额片区
        getBalanceamount() {
            const path = '/interfaceApi/report/leadercockpit/drill/balanceamount/' + this.pian_q_id;
            return this.$axios.$get(path, { defEx: true });
        },
        // 折线柱图
        lineColumn(obj, data) {
            const lineColumnChart = window.$globalHub.$echarts.init(document.getElementById(obj));
            const option = {
                title: {
                    text: '',
                    x: 'center',
                    y: 0,
                    textStyle: {
                        color: '#B4B4B4',
                        fontSize: 16,
                        fontWeight: 'normal',
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            show: true,
                        },
                    },
                },
                legend: {
                    textStyle: {
                        color: '#fff',
                    },
                    top: 0,
                    right: 10,
                },
                grid: {
                    left: 60,
                    top: 50,
                    right: 60,
                    bottom: 40,
                },
                xAxis: [{
                    // type:'category',
                    data: [],
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                        color: 'rgba(255,255,255,0.1)',
                    },
                    splitArea: {
                        color: '#f00',
                        lineStyle: {
                            color: '#f00',
                        },
                    },
                    axisLabel: {
                        color: 'rgba(255,255,255,0.5)',
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                yAxis: [
                    {
                        name: '单位(万元)',
                        // nameLocation: 'middle',
                        nameTextStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            padding: '',
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisLine: {
                            show: true,
                            color: 'rgba(255,255,255,0.1)',
                        },
                        axisLabel: {
                            show: true,
                            color: 'rgba(255,255,255,0.5)',
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    {
                        name: '单位(%)',
                        // nameLocation: 'middle',
                        nameTextStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            padding: '',
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisLine: {
                            show: true,
                            color: 'rgba(255,255,255,0.1)',
                        },
                        axisLabel: {
                            show: true,
                            color: 'rgba(255,255,255,0.5)',
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '累计应收',
                        barWidth: 15,
                        type: 'bar',
                        barGap: '-100%', // 重疊
                        itemStyle: {
                            normal: {
                                color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#CD63F9',
                                },
                                {
                                    offset: 1,
                                    color: '#9759D5',
                                },
                                ]),
                            },
                        },
                        data: data.receivable,
                    },
                    {
                        name: '累计实收',
                        type: 'bar',
                        barWidth: 15,
                        yAxisIndex: 0,
                        stack: '累计应收',
                        itemStyle: {
                            normal: {
                                color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#AED563',
                                },
                                {
                                    offset: 1,
                                    color: '#78D563',
                                },
                                ]),
                            },
                        },
                        data: data.receipt,
                    },
                    {
                        name: '应收账款余额',
                        type: 'bar',
                        barWidth: 15,
                        yAxisIndex: 0,
                        stack: '累计应收',
                        itemStyle: {
                            normal: {
                                color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#24CCFC',
                                },
                                {
                                    offset: 1,
                                    color: '#2F80F2',
                                },
                                ]),
                            },
                        },
                        data: data.receivable_balance,
                    },
                    {
                        name: '货款回收率',
                        type: 'line',
                        // smooth: true,
                        showSymbol: true,
                        symbolSize: 8,
                        lineStyle: {
                            normal: {
                                color: '#FC9343',
                            },
                        },
                        yAxisIndex: 1,
                        label: {
                            show: false,
                            position: 'top',
                            textStyle: {
                                color: '#FC9343',
                            },
                        },
                        itemStyle: {
                            color: '#6c50f3',
                            borderColor: '#fff',
                            borderWidth: 1,
                            shadowColor: 'rgba(0, 0, 0, .3)',
                            shadowBlur: 0,
                            shadowOffsetY: 2,
                            shadowOffsetX: 2,
                            normal: {
                                color: '#FC9343',
                                lineStyle: {
                                    color: '#FC9343',
                                },
                            },
                        },
                        data: data.rate,
                    },
                ],
            };
            option.xAxis[0].data = data.xAxis.data;
            lineColumnChart.setOption(option);
            lineColumnChart.off('click');
            lineColumnChart.on('click', param => {
                if (this.pian_q_id === '-') {
                    this.accountDrill(param.name);
                } else {
                    this.toEnter(param.name);
                }

            });
        },
    },
};
</script>
<style lang="stylus">
@import './areaDrill.styl'
</style>